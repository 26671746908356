import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { themeColors } from "theme/theme.colors";

export const SOrderCard = styled(motion.div)`
  background-color: ${themeColors.whiteDark};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 30px;
`;

export const SOrderCardHeader = styled(motion.div)`
  background-color: ${themeColors.whiteLight};
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
`;

export const SOrderCardHeaderInner = styled(motion.div)`
  & p {
    margin-bottom: 4px;
  }
`;

export const SOrderCardContent = styled(motion.div)`
  padding: 17px 20px;
`;
