import React from "react";
import { TColors } from "theme/theme.colors";
import { TFonts } from "theme/theme.fonts";
import { TDisplay, TTextAlign } from "theme/types/cssTypes";

import { STypography } from "./Typography.styles";

export interface ITypography {
  children?: React.ReactNode;
  type: keyof TFonts;
  as: keyof JSX.IntrinsicElements;
  color?: keyof TColors;
  fontWeight?: number;
  align?: TTextAlign;
  textTransform?: "uppercase" | "lowercase" | "capitalize" | "none";
  display?: TDisplay;
  margin?: string;
  whiteSpace?: "nowrap" | "break-word" | "normal";
  maxWidth?: string;
  minWidth?: string;
  m?: string;
  p?: string;
  zIndex?: number;
  bold?: boolean;
  onClick?: () => void;
  cursor?: "pointer" | "default" | "inherit";
  dangerouslySetInnerHTML?: { __html: string } | undefined;
  hoverColor?: keyof TColors;
}

export const Typography = React.forwardRef<HTMLParagraphElement | HTMLHeadingElement, ITypography>(
  ({ children, type, as, color, fontWeight, dangerouslySetInnerHTML, onClick, align = "center", ...rest }, ref) => {
    return (
      <STypography
        ref={ref}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        as={as}
        type={type}
        onClick={onClick}
        cursor={!!onClick ? "pointer" : "default"}
        color={color}
        fontWeight={fontWeight}
        align={align}
        {...rest}
      >
        {children}
      </STypography>
    );
  }
);

Typography.displayName = "Typography";
