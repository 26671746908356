import { useTranslation } from "react-i18next";

import { Typography } from "components/common/Typography/Typography";

import PaymentResolutionPage from "../components/PaymentResolutionPage";

const PaymentCanceled = () => {
  const { t } = useTranslation();
  return (
    <PaymentResolutionPage status="canceled">
      <Typography type="p2" as="h1" fontWeight={500}>
        {t("payment.payment-canceled")}
      </Typography>
    </PaymentResolutionPage>
  );
};

export default PaymentCanceled;
