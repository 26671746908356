import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { themeQueries } from "theme/theme.breakpoints";
import { themeColors } from "theme/theme.colors";

import { Typography } from "components/common/Typography/Typography";

import { IPaymentResolutionPage } from "./PaymentResolutionPage";

interface ISPaymentResolutionPage extends Omit<IPaymentResolutionPage, "children"> {}

export const SPaymentResolution = styled(motion.div, {})<ISPaymentResolutionPage>`
  padding: 2em;
  background-color: ${(props) => (props.status === "success" ? "#26a17b" : themeColors.error)};
  border-radius: 7px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 100%;
  justify-content: center;
`;
export const SPaymentResolutionInner = styled.div`
  padding: 10px 0px;
`;

export const SPaymentResolutionTitle = styled(Typography)`
  margin-top: 1.25rem;
  ${themeQueries.tablet} {
    margin-top: 4.0625rem;
  }
`;
