import React, { ButtonHTMLAttributes } from "react";
import { EmailOutline } from "@emotion-icons/evaicons-outline/EmailOutline";

import { Typography } from "components/common/Typography/Typography";
import Loader from "components/ui/Loader/Loader";

import { SEmailButton, SEmailButtonInner } from "./EmailButton.styles";

export interface IButton {
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

interface IButtonExtended extends IButton, Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children"> {}

const EmailButton: React.FC<IButtonExtended> = ({ onClick, text, isLoading = false, isDisabled = false, ...rest }) => {
  const isActive = !isLoading && !isDisabled;

  return (
    <SEmailButton
      tabIndex={0}
      style={{
        ...rest.style,
      }}
      whileTap={{
        scale: 0.98,
      }}
      onClick={onClick}
      disabled={!isActive}
    >
      {isLoading ? (
        <Loader size="small" />
      ) : (
        <SEmailButtonInner>
          <EmailOutline size={16} color="#F154B7" style={{ paddingRight: "1rem" }} />
          <Typography cursor="inherit" as="p" type="labelSmall" color="inherit">
            {text}
          </Typography>
        </SEmailButtonInner>
      )}
    </SEmailButton>
  );
};

export default EmailButton;
