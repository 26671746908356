import { ReactComponent as LogoSmallSvg } from "assets/svg/logo/logo-small.svg";
import { ReactComponent as LogoSmallSvgBg } from "assets/svg/logo/logo-small-bg.svg";
import { motion } from "framer-motion";

interface ILoader {
  zIndex?: number;
  size?: "small" | "large";
}

const Loader: React.FC<ILoader> = ({ zIndex = 1, size = "large" }) => {
  const bgDimensions = size === "small" ? 50 : 124;
  const logoDimensions = size === "small" ? 25 : 78;

  return (
    <div style={{ position: "relative", zIndex, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div style={{ position: "absolute" }}>
        <LogoSmallSvgBg width={bgDimensions} height={bgDimensions} />
      </div>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1.6, repeatDelay: 0.2, type: "spring" }}
      >
        <LogoSmallSvg width={logoDimensions} height={logoDimensions} />
      </motion.div>
    </div>
  );
};

export default Loader;
