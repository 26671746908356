import { useQuery, UseQueryResult } from "react-query";
import { toast } from "react-toastify";
import { api } from "api";
import { AxiosResponse } from "axios";
import { GenericError, IGenericError } from "types/response/errors/generic-errors";

import { queryKeys } from "constants/queryKeys";

interface IPaymentTreshold {
  currency: string;
  amount: number;
}

export interface IMerchantSettingsResponse {
  allowedCurrencies: Array<string>;
  paymentTresholds: Array<IPaymentTreshold>;
  displayName: string;
  base64LogoImage: string | null;
}

export interface IMerchantSettingsQueryResponse extends Omit<IMerchantSettingsResponse, "base64LogoImage"> {
  logoImage: string | null;
}

const useMerchantSettingsQuery = ({
  merchantId,
  enabled = true,
}: {
  merchantId?: string;
  enabled?: boolean;
}): UseQueryResult<IMerchantSettingsQueryResponse, IGenericError> => {
  const fetchMerchantSettings = async () => {
    const res = await api
      .get<void, AxiosResponse<IMerchantSettingsResponse>>(`merchants/${merchantId}/settings`)
      .catch((err) => {
        toast.error(err.message);
        throw new GenericError(err.message);
      });

    return { ...res.data, logoImage: res.data.base64LogoImage };
  };

  return useQuery([queryKeys.merchantSettings, merchantId], fetchMerchantSettings, {
    retry: false,
    enabled: enabled && !!merchantId,
  });
};

export default useMerchantSettingsQuery;
