import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import PageLayout from "components/ui/PageLayout/PageLayout";
import PageLoader from "components/ui/PageLoader/PageLoader";
import { routeConfig } from "constants/routeConfig";
import { sessionStorageItems } from "constants/storage";
import useRedirectCanceledOrder from "hooks/navigation/useRedirectCanceledOrder";
import useGetToken from "hooks/services/useAuthorize";

interface ITokenContext {
  isAuthorizing: boolean;
  isTokenError: boolean;
}

export const TokenContext = React.createContext<ITokenContext>({} as ITokenContext);

const RouteManager: React.FC = () => {
  const location = useLocation();
  const { isLoading: isAuthorizing, isError: isTokenError, mutate: getToken } = useGetToken();
  useRedirectCanceledOrder();

  useEffect(() => {
    if (!isTokenError) {
      return;
    }
    sessionStorage.removeItem(sessionStorageItems.token);
  }, [isTokenError]);

  useEffect(() => {
    getToken({
      clientId: process.env.REACT_APP_CLIENT_ID || "",
      clientSecret: process.env.REACT_APP_CLIENT_SECRET || "",
    });
    return () => {
      sessionStorage.removeItem(sessionStorageItems.token);
    };
  }, [getToken]);

  return (
    <AnimatePresence mode="sync">
      <TokenContext.Provider value={{ isAuthorizing, isTokenError }}>
        <Routes location={location} key={location.pathname}>
          {Object.entries(routeConfig).map(([key, route]) => {
            const { path, component: Cmp } = route;
            return (
              <Route
                key={key}
                path={path}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <PageLayout>
                      <Cmp />
                    </PageLayout>
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </TokenContext.Provider>
    </AnimatePresence>
  );
};

export default RouteManager;
