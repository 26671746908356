import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@emotion/react";
import { theme } from "theme";

import RouteManager from "components/routing/RouteManager";
import AppWrapper from "components/ui/AppWrapper/AppWrapper";
import ToastContainer from "components/ui/ToastContainer/ToastContainer";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <RouteManager />
          <ToastContainer />
        </AppWrapper>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
