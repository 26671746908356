export class AuthorizationError extends Error {
  public error: string;
  public errorDescription: string;
  public errorUri: string;
  constructor({ error, errorDescription, errorUri }: { error: string; errorDescription: string; errorUri: string }) {
    super();
    this.error = error;
    this.errorDescription = errorDescription;
    this.errorUri = errorUri;
  }
}
export interface IAuthorizationErrorData {
  error: string;
  error_description: string;
  error_uri: string;
}
