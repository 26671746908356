import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import { themeColors } from "theme/theme.colors";

export const StyledToastContainer = styled(ToastContainer)(() => ({
  fontFamily: "inherit !important",
  "& .Toastify__close-button": {
    color: themeColors.blueMain,
    opacity: 1,
  },
  "& > div": {
    borderRadius: "1rem",
    border: `solid 2px ${themeColors.blueDark}`,
    background: themeColors.purpleDark,
  },
  "& div": {
    color: themeColors.white,
    whiteSpace: "pre-line",
    fontFamily: "inherit !important",
  },
}));
