import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { searchConst } from "constants/query";
import { routes } from "constants/routeConfig";
import { sessionStorageItems } from "constants/storage";

const useRedirectCanceledOrder = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get(searchConst.orderId) || "";
  const isCanceled = sessionStorage.getItem(sessionStorageItems.isCanceled);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCanceled || !orderId) {
      sessionStorage.removeItem(sessionStorageItems.isCanceled);
      return;
    }
    navigate({ pathname: routes.canceled, search: `${searchConst.orderId}=${orderId}` });
  }, [isCanceled, navigate, orderId]);
};

export default useRedirectCanceledOrder;
