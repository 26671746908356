import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useSearchParams } from "react-router-dom";
import { ReactComponent as Failure } from "assets/svg/status/failure.svg";
import { ReactComponent as Success } from "assets/svg/status/success.svg";
import { AnimatePresence, motion } from "framer-motion";

import { Typography } from "components/common/Typography/Typography";
import { searchConst } from "constants/query";
import { routeConfig } from "constants/routeConfig";
import useOrderQuery, { PaymentStatusEnum } from "hooks/services/useOrderQuery";
import useTimer from "hooks/time/useTimer";

import { SPaymentResolution, SPaymentResolutionInner } from "./PaymentResolutionPage.styles";

export interface IPaymentResolutionPage {
  children: React.ReactNode;
  status: "success" | "failure" | "canceled";
}

const PaymentResolutionPage: React.FC<IPaymentResolutionPage> = ({ children, status }) => {
  const redirectAtTimestamp = useMemo(() => new Date().getTime() + 6000, []);
  const { timeLeft, finished } = useTimer(redirectAtTimestamp);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get(searchConst.orderId) || "";
  const statusFromQuery = searchParams.get(searchConst.status) || "";
  const { data: order } = useOrderQuery({ orderId });
  const location = useLocation();
  const isSuccessPage = !!matchPath(location.pathname, routeConfig.success.path);

  const redirectBasedOnStatus = useCallback(() => {
    if (!order) {
      return;
    }
    sessionStorage.clear();
    if (isSuccessPage) {
      return (window.location.href = encodeURI(order?.acceptUrl));
    } else {
      return (window.location.href = encodeURI(order?.cancelUrl));
    }
  }, [isSuccessPage, order]);

  useEffect(() => {
    if (finished && !!order && !statusFromQuery) {
      redirectBasedOnStatus();
    }
  }, [finished, order, redirectBasedOnStatus, statusFromQuery]);

  return (
    <SPaymentResolution status={status}>
      <AnimatePresence>
        <motion.div
          key="status"
          initial={{
            scale: 0,
          }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2 }}
        >
          {status === "success" ? <Success /> : <Failure />}
        </motion.div>
        <motion.div
          key="payment-resolution-inner"
          initial={{
            scale: 0,
          }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.4 }}
        >
          {!!order && !statusFromQuery ? (
            <>
              <SPaymentResolutionInner>{children}</SPaymentResolutionInner>
              <Typography type="labelSmall" as="p">
                {t("payment.redirecting", { count: timeLeft || 0 })}
              </Typography>
            </>
          ) : (
            <SPaymentResolutionInner>
              <Typography type="p2" as="h1">
                {!orderId
                  ? t("payment.order-not-found")
                  : statusFromQuery === PaymentStatusEnum.PAID.toLowerCase()
                  ? t("payment.already-paid")
                  : t("payment.order-is-obsolete")}
              </Typography>
            </SPaymentResolutionInner>
          )}
        </motion.div>
      </AnimatePresence>
    </SPaymentResolution>
  );
};

export default PaymentResolutionPage;
