import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import { AuthorizationError, IAuthorizationErrorData } from "types/response/errors/authorization-errors";

import { sessionStorageItems } from "constants/storage";
import { IToken } from "hooks/services/useAuthorize";

export const connect = axios.create({
  baseURL: process.env.REACT_APP_CONNECT_API_ADDRESS,
});

interface IAccessToken {
  client_id: string;
  exp: number;
  iat: number;
  iss: string;
  oi_prst: string;
  oi_tkn_id: string;
  role: string;
  sub: string;
}

export const isTokenExpired = () => {
  const token = JSON.parse(sessionStorage.getItem(sessionStorageItems.token) || "[]")?.access_token;
  if (!token) return true;

  const decoded = jwt_decode<IAccessToken>(token);
  const expiresAt = decoded?.exp;
  //giving 1s for buffer
  return expiresAt - 1 <= Math.ceil(Date.now() / 1000);
};

export const getRefreshedToken = async (): Promise<IToken> => {
  const params = new URLSearchParams();
  const clientId = process.env.REACT_APP_CLIENT_ID || "";
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET || "";

  params.append("grant_type", "client_credentials");
  params.append("client_id", clientId);
  params.append("client_secret", clientSecret);
  const res = await connect.post<void, AxiosResponse<IToken, AuthorizationError>>("/token", params).catch((err) => {
    const errData: IAuthorizationErrorData = err.response.data;
    throw new AuthorizationError({
      error: errData.error,
      errorDescription: errData.error_description,
      errorUri: errData.error_uri,
    });
  });
  setToken(res.data);
  return res.data;
};

const setToken = (token: IToken) => {
  sessionStorage.setItem(sessionStorageItems.token, JSON.stringify(token));
};
