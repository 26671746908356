import { getRefreshedToken, isTokenExpired } from "api/connect";
import axios from "axios";

import { sessionStorageItems } from "constants/storage";

export const api = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_API_ADDRESS,
});

api.interceptors.request.use(
  async (config) => {
    if (isTokenExpired()) {
      await getRefreshedToken();
    }
    const token = JSON.parse(sessionStorage.getItem(sessionStorageItems.token) || "[]")?.access_token;
    if (
      !!token &&
      // !!config?.headers?.Authorization &&
      // TODO remove this check when dev is done
      config.url !== "orders/authorize"
    ) {
      // eslint-disable-next-line no-param-reassign
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
