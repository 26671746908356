const fontFamily = {
  roobert: "Roobert ,arial, sans-serif",
};

export const themeFonts = {
  heading1: {
    fontFamily: fontFamily.roobert,
    fontSize: "3.875rem",
    lineHeight: "4.65rem",
    fontWeight: 400,
  },
  p1: {
    fontFamily: fontFamily.roobert,
    fontSize: "2rem",
    lineHeight: "2.8125rem",
    fontWeight: 300,
  },
  p2: {
    fontFamily: fontFamily.roobert,
    fontSize: "2.5rem",
    lineHeight: "2.8125rem",
    fontWeight: 700,
  },
  label: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.5rem",
    lineHeight: "2.8125rem",
    fontWeight: 500,
  },
  buttonTitle: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.3rem",
    lineHeight: "1.8rem",
    fontWeight: 500,
  },
  input: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 300,
  },
  labelSmall: {
    fontFamily: fontFamily.roobert,
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 500,
  },
  labelExtraSmall: {
    fontFamily: fontFamily.roobert,
    fontSize: "0.9rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
  },
};

export const themeFontsMobile = {
  heading1: {
    fontFamily: fontFamily.roobert,
    fontSize: "2.375rem",
    lineHeight: "2.85rem",
    fontWeight: 400,
  },
  p1: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.25rem",
    lineHeight: "1.375rem",
    fontWeight: 300,
  },
  p2: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.5rem",
    lineHeight: "1.625rem",
    fontWeight: 700,
  },
  label: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.25rem",
    lineHeight: "1.5625rem",
    fontWeight: 500,
  },
  buttonTitle: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
  },
  input: {
    fontFamily: fontFamily.roobert,
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 300,
  },
  labelSmall: {
    fontFamily: fontFamily.roobert,
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 500,
  },
  labelExtraSmall: {
    fontFamily: fontFamily.roobert,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    fontWeight: 500,
  },
};
export type TFonts = typeof themeFonts;
