import { lazy } from "react";

import PaymentCanceled from "components/pages/Payment/PaymentCanceled/PaymentCanceled";

const Home = lazy(() => import("components/pages/Home/Home"));
const CurrencyList = lazy(() => import("components/pages/CurrencySelection/CurrencyList"));
const NotFound = lazy(() => import("components/pages/NotFound/NotFound"));
const PaymentPending = lazy(() => import("components/pages/Payment/PaymentPending/PaymentPending"));
const PaymentSuccess = lazy(() => import("components/pages/Payment/PaymentSuccess/PaymentSuccess"));
const PaymentFailure = lazy(() => import("components/pages/Payment/PaymentFailure/PaymentFailure"));

export const routes = {
  home: "/",
  currencySelection: "/select-currency",
  pendingPayment: "/payment-pending",
  success: "/payment-success",
  failure: "/payment-failure",
  canceled: "/payment-canceled",
  notFound: "/not-found",
};

export const routeConfig = {
  home: {
    path: routes.home,
    component: Home,
  },
  currencySelection: {
    path: routes.currencySelection,
    component: CurrencyList,
  },
  pendingPayment: {
    path: routes.pendingPayment,
    component: PaymentPending,
  },
  success: {
    path: routes.success,
    component: PaymentSuccess,
  },
  failure: {
    path: routes.failure,
    component: PaymentFailure,
  },
  canceled: {
    path: routes.canceled,
    component: PaymentCanceled,
  },
  notFound: {
    path: "*",
    component: NotFound,
  },
};
