import { themeColors } from "theme/theme.colors";

import { SBackdrop } from "./Backdrop.styles";

interface IBackdrop {
  onClick?: () => void;
  children: React.ReactNode;
}

const Backdrop: React.FC<IBackdrop> = ({ onClick, children }) => {
  return (
    <SBackdrop
      role="dialog"
      aria-modal="true"
      onClick={onClick}
      style={{
        top: 0,
        left: 0,
        zIndex: "100",
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: themeColors.blackTransparent,
      }}
      initial={{
        opacity: 0.5,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
    >
      {children}
    </SBackdrop>
  );
};

export default Backdrop;
