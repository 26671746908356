import { useMutation, UseMutationResult } from "react-query";
import { toast } from "react-toastify";
import { connect } from "api";
import { AxiosResponse } from "axios";
import { AuthorizationError, IAuthorizationErrorData } from "types/response/errors/authorization-errors";

import { sessionStorageItems } from "constants/storage";

export interface IToken {
  access_token: string;
  expires_in: number;
  token_type: string;
}

const useGetToken = (): UseMutationResult<IToken, AuthorizationError, { clientId: string; clientSecret: string }> => {
  const fetchToken = async ({
    clientId,
    clientSecret,
  }: {
    clientId: string;
    clientSecret: string;
  }): Promise<IToken> => {
    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");
    params.append("client_id", clientId);
    params.append("client_secret", clientSecret);
    const res = await connect.post<void, AxiosResponse<IToken, AuthorizationError>>("/token", params).catch((err) => {
      const errData: IAuthorizationErrorData = err.response.data;
      throw new AuthorizationError({
        error: errData.error,
        errorDescription: errData.error_description,
        errorUri: errData.error_uri,
      });
    });
    return res.data;
  };

  return useMutation<IToken, AuthorizationError, { clientId: string; clientSecret: string }>(fetchToken, {
    onSuccess: (data) => {
      sessionStorage.setItem(sessionStorageItems.token, JSON.stringify(data));
    },
    onError: (err: AuthorizationError) => {
      sessionStorage.removeItem(sessionStorageItems.token);
      toast.error(err.errorDescription);
    },
  });
};

export default useGetToken;
