import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "components/common/Typography/Typography";

import { SOrderCard, SOrderCardContent, SOrderCardHeader, SOrderCardHeaderInner } from "./OrderCard.styles";

export interface IOrderCard {
  orderName: string;
  paymentAmount: number;
  paymentCurrency: string;
  cryptoAmount?: number | null;
  cryptoCurrency?: string | null;
  merchantLogoBase64?: string | null;
  merchantName?: string;
}

const OrderCard: React.FC<IOrderCard> = ({
  orderName,
  paymentAmount,
  paymentCurrency,
  cryptoAmount,
  cryptoCurrency,
  merchantName,
  merchantLogoBase64,
}) => {
  const { t } = useTranslation();
  return (
    <SOrderCard>
      <SOrderCardHeader>
        <SOrderCardHeaderInner>
          <Typography as="p" type="labelExtraSmall" color="black" align="left">
            {orderName}
          </Typography>
          <Typography
            as="p"
            type="labelSmall"
            color="black"
            align="left"
            bold
          >{`${paymentAmount} ${paymentCurrency}`}</Typography>
        </SOrderCardHeaderInner>
        {merchantLogoBase64 ? (
          <img src={`data:image/*;base64,${merchantLogoBase64}`} alt="store logo" height={45} />
        ) : (
          <Typography as="p" type="labelSmall" color="black" align="right">
            {merchantName ?? "-"}
          </Typography>
        )}
      </SOrderCardHeader>
      <SOrderCardContent>
        <Typography as="p" type="labelExtraSmall" color="black" align="left">
          {t("global.order.crypto")}
        </Typography>
        <Typography as="p" type="label" color={cryptoAmount ? "purpleMain" : "black"} bold align="left">
          {cryptoAmount && cryptoCurrency ? `${cryptoAmount} ${cryptoCurrency}` : "-"}
        </Typography>
      </SOrderCardContent>
    </SOrderCard>
  );
};

export default OrderCard;
