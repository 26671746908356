import styled from "@emotion/styled";
import { theme } from "theme";

import { ITypography } from "./Typography";

interface IStyledTypography extends ITypography {}

export const STypography = styled.p<IStyledTypography>`
  font-family: ${(props) => theme.fonts[props.type].fontFamily};
  font-size: ${(props) => theme.fontsMobile[props.type].fontSize};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || theme.fontsMobile[props.type].fontWeight)};
  line-height: ${(props) => theme.fontsMobile[props.type].lineHeight};
  text-align: ${(props) => props.align || "left"};
  color: ${(props) => theme.colors[props.color || "white"]};
  cursor: ${(props) => props.cursor || "text"};
  text-transform: ${(props) => props.textTransform || "none"};
  display: ${(props) => props.display || "block"};
  white-space: ${(props) => props.whiteSpace || "normal"};
  min-width: ${(props) => props.minWidth && props.minWidth};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  margin: ${(props) => props.m || "0"};
  padding: ${(props) => props.p || "unset"};
  z-index: ${(props) => props.zIndex ?? "initial"};
  white-space: ${(props) => props.whiteSpace || "normal"};
  position: relative;
  &:hover {
    color: ${(props) => props.hoverColor && theme.colors[props.hoverColor]};
  }
  ${theme.queries.lgDesktop} {
    font-size: ${(props) => theme.fonts[props.type].fontSize};
    font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || theme.fonts[props.type].fontWeight)};
    line-height: ${(props) => theme.fonts[props.type].lineHeight};
  }
`;
