import { useQuery, UseQueryResult } from "react-query";
import { toast } from "react-toastify";
import { api } from "api";
import { AxiosResponse } from "axios";
import i18n from "i18n/i18n";
import { GenericError, IGenericError } from "types/response/errors/generic-errors";

import { queryKeys } from "constants/queryKeys";

export enum PaymentStatusEnum {
  PENDING = "PaymentPending",
  PAID = "Paid",
  DECLINED = "PaymentDeclined",
  AUTHORIZED = "Authorized",
}
export type TOrderResponse = {
  authorizedAtUtc: string | null;
  checkoutAtUtc: string | null;
  expireAtUtc: string | null;
  orderId: string;
  isEligibleForLightningNetwork: boolean;
  paymentAmount: number;
  paymentCurrency: string;
  cryptoPaymentCurrency: string | null;
  cryptoPaymentAmount: number | null;
  cryptoPaymentAddress: string | null;
  status: PaymentStatusEnum;
  cancelUrl: string;
  acceptUrl: string;
  description: string;
  customerEmail: string | null;
  merchantId: string;
};

const useOrderQuery = ({
  orderId,
  enabled = true,
}: {
  orderId: string;
  enabled?: boolean;
}): UseQueryResult<TOrderResponse, IGenericError> => {
  const fetchOrder = async () => {
    const res = await api.get<void, AxiosResponse<TOrderResponse>>(`orders/${orderId}`).catch((err) => {
      if (err?.response?.data?.errors?.orderId) {
        toast.error(i18n.t("error.order-not-valid", { orderId }));
        throw new GenericError(err.message);
      }
      toast.error(err.message);
      throw new GenericError(err.message);
    });
    return res.data;
  };

  return useQuery([queryKeys.order, orderId], fetchOrder, { retry: false, enabled: enabled && !!orderId });
};

export default useOrderQuery;
