import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { themeQueries } from "theme/theme.breakpoints";
import { themeColors } from "theme/theme.colors";

export const SEmailButton = styled(motion.button)`
  background-color: ${themeColors.purpleAlternative};
  max-width: 100%;
  border: none;
  padding: 10px 5px;
  border-radius: 7px;
  outline: none;
  color: ${themeColors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  &:focus-visible {
    transition: all 0.2s ease;
    background: ${themeColors.purpleDark}60!important;
  }
  z-index: 1;
  ${themeQueries.mobile} {
    padding: 10px 20px;
  }
`;

export const SEmailButtonInner = styled(motion.div)`
  display: flex;
  width: 100%;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    text-align: left;
  }
`;
