import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import Backdrop from "components/ui/Backdrop/Backdrop";
import Loader from "components/ui/Loader/Loader";

import { SPageLoader } from "./PageLoader.styles";

const PageLoader = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return createPortal(
    <>
      {show && (
        <Backdrop>
          <SPageLoader>
            <Loader zIndex={101} />
          </SPageLoader>
        </Backdrop>
      )}
    </>,
    document.body
  );
};

export default PageLoader;
