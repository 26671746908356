import Background from "components/common/Background/Background";

import { SAppWrapper, SAppWrapperInner } from "./AppWrapper.styles";

interface IAppWrapper {
  children: React.ReactNode;
}

const AppWrapper: React.FC<IAppWrapper> = ({ children }) => {
  return (
    <SAppWrapper>
      <Background />
      <SAppWrapperInner>{children}</SAppWrapperInner>
    </SAppWrapper>
  );
};

export default AppWrapper;
