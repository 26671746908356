import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as LogoSvg } from "assets/svg/logo/logo.svg";
import { EmailModalContext } from "context/EmailModalContext";

import EmailButton from "components/common/EmailButton/EmailButton";
import OrderCard from "components/common/OrderCard/OrderCard";
import { Typography } from "components/common/Typography/Typography";
import { searchConst } from "constants/query";
import { useIsMedium } from "hooks/media-queries/useIsMedium";
import useMerchantSettingsQuery from "hooks/services/useMerchantSettingsQuery";
import useOrderQuery from "hooks/services/useOrderQuery";

import { SPageColumn, SPageContent, SPageHeader, SPageRow, SPageWrapper } from "./PageLayout.styles";

interface IPageLayout {
  children: React.ReactNode;
}

const PageLayout: React.FC<IPageLayout> = ({ children }) => {
  const { t } = useTranslation();
  const isNotMobile = useIsMedium();

  const [searchParams] = useSearchParams();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const orderId = searchParams.get(searchConst.orderId) || "";
  const { data: order } = useOrderQuery({ orderId });
  const { data: merchantSettings } = useMerchantSettingsQuery({ merchantId: order?.merchantId });

  return (
    <SPageWrapper
      initial={{ transform: "translateX(100%)" }}
      animate={{ transform: "translateX(0%)" }}
      exit={{ transform: "translateX(-100%)" }}
    >
      <SPageHeader>
        <LogoSvg width={isNotMobile ? 205 : 150} height={40} display="block" />
        {order && (
          <EmailButton onClick={() => setIsEmailModalOpen(true)} text={order.customerEmail ?? t("global.no-email")} />
        )}
      </SPageHeader>
      <SPageRow>
        {order && (
          <SPageColumn>
            <Typography type="p2" as="h1" align="left" bold>
              {t("payment.ammount-to-pay")}
            </Typography>

            <OrderCard
              paymentAmount={order.paymentAmount}
              paymentCurrency={order.paymentCurrency}
              orderName={order.description}
              cryptoAmount={order.cryptoPaymentAmount}
              cryptoCurrency={order.cryptoPaymentCurrency}
              merchantName={merchantSettings?.displayName}
              merchantLogoBase64={merchantSettings?.logoImage}
            />
          </SPageColumn>
        )}
        <SPageContent>
          <EmailModalContext.Provider value={{ isOpen: isEmailModalOpen, setIsOpen: setIsEmailModalOpen }}>
            {children}
          </EmailModalContext.Provider>
        </SPageContent>
      </SPageRow>
    </SPageWrapper>
  );
};

export default PageLayout;
