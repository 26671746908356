import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { themeQueries } from "theme/theme.breakpoints";

export const SPageWrapper = styled(motion.div)`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  display: block;
  padding: 1em;
  max-width: 500px;
  ${themeQueries.smDesktop} {
    max-width: 1000px;
  }
`;

export const SPageRow = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 30px;
  ${themeQueries.smDesktop} {
    flex-direction: row;
  }
`;

export const SPageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
  ${themeQueries.mobile} {
    flex-direction: row;
  }
`;

export const SPageColumn = styled.div`
  min-width: 320px;
  max-height: 602px;
  ${themeQueries.smDesktop} {
    flex: 1;
  }
`;

export const SPageContent = styled.div`
  display: flex;
  min-width: 320px;
  overflow-y: auto;
  height: 602px;
  position: relative;
  margin-bottom: 1em;
  ${themeQueries.smDesktop} {
    flex: 1;
  }
`;
