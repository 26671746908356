export const themeBreakpoints = {
  xsMobile: "0px",
  mobile: "480px",
  tablet: "767px",
  smDesktop: "1024px",
  desktop: "1280px",
  lgDesktop: "1440px",
  xlDesktop: "1600px",
};

export const themeQueries = {
  xsMobile: `@media screen and (min-width: ${themeBreakpoints.xsMobile})`,
  mobile: `@media screen and (min-width: ${themeBreakpoints.mobile})`,
  tablet: `@media screen and (min-width: ${themeBreakpoints.tablet})`,
  smDesktop: `@media screen and (min-width: ${themeBreakpoints.smDesktop})`,
  desktop: `@media screen and (min-width: ${themeBreakpoints.desktop})`,
  lgDesktop: `@media screen and (min-width: ${themeBreakpoints.lgDesktop})`,
  xlDesktop: `@media screen and (min-width: ${themeBreakpoints.xlDesktop})`,
};
