export const themeColors = {
  blueMain: "#97D3F0",
  blueLight: "#B0F1FB",
  blueDark: "#89B5F6",
  purpleLight: "#DC26E0",
  purpleDark: "#410D78",
  purpleMain: "#3E1099",
  black: "#000000",
  blackOpaque: "#00000050",
  blackTransparent: "#F9F9F980",
  white: "#FFFFFF",
  whiteOpaque: "#FFFFFF50",
  whiteDark: "#f9f9f9",
  error: "#D6372C",
  info: "#F7931A",
  gray: "#7C7C7C",
  grayLight: "#DDDDDD",
  lightGray: "#f1f1f1",
  whiteLight: "#EFEFEF",
  purpleAlternative: "#5101aa",
  inherit: "inherit",
  transparent: "transparent",
};

export type TColors = typeof themeColors;
