import { useEffect, useState } from "react";

import { getTimeleftTillTimestamp } from "helpers/time/getTimeleftTillTimestamp";

const useTimer = (initialTime: number | null) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    const timerInterval = setInterval(
      () =>
        setTimeLeft((prev) => {
          if (!prev && prev !== 0) {
            return 1;
          }
          if (prev > 0) {
            if (!initialTime) {
              return 1;
            }
            return getTimeleftTillTimestamp(initialTime);
          } else {
            clearInterval(timerInterval);
            setFinished(true);
            return 0;
          }
        }),
      100
    );

    return () => {
      clearInterval(timerInterval);
    };
  }, [initialTime]);
  return { timeLeft, finished };
};

export default useTimer;
