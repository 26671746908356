import styled from "@emotion/styled";
import { themeColors } from "theme/theme.colors";

export const SAppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(0deg, ${themeColors.purpleMain}30, ${themeColors.purpleMain}30),
    linear-gradient(0deg, ${themeColors.purpleMain}30, ${themeColors.purpleMain}30),
    linear-gradient(332.93deg, ${themeColors.purpleLight} -0.82%, ${themeColors.purpleDark} 64.8%);
  *::-webkit-scrollbar {
    width: 5px;
    border-radius: 1rem;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    min-height: 24px;
    background-color: ${themeColors.grayLight};
  }
  *::-webkit-scrollbar-track {
    border-radius: 1rem;
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb:focus {
    background-color: ${themeColors.gray};
  }
  *::-webkit-scrollbar-thumb:active {
    background-color: ${themeColors.gray};
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.gray};
  }
`;

export const SAppWrapperInner = styled.div`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
`;
