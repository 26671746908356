import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { themeColors } from "theme/theme.colors";

export const SBackdrop = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: ${themeColors.blackTransparent};
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
`;
