import { StyledToastContainer } from "./ToastContainer.styles";

import "react-toastify/dist/ReactToastify.css";

const ToastContainer = () => {
  return (
    <StyledToastContainer
      limit={3}
      position="bottom-right"
      autoClose={5000}
      newestOnTop={false}
      hideProgressBar
      closeOnClick
      rtl={false}
      pauseOnHover
    />
  );
};

export default ToastContainer;
