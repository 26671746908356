import { ReactComponent as BackgroundImage } from "assets/svg/background/background.svg";

import { SBackground } from "./Background.styles";

const Background = () => (
  <SBackground>
    <BackgroundImage />
  </SBackground>
);

export default Background;
